import { instance } from "./api";

export class TicketService {
  static getTicketDetails = async (event_ticket_number: string) => {
    try {
      const response = await instance({
        method: "GET",
        url: `/events/${event_ticket_number}/detail`,
      });
      return response.data;
    } catch (ex: any) {
      throw ex;
    }
  };
  static confirm = async (event_ticket_number: string, data: any) => {
    try {
      const response = await instance({
        method: "POST",
        url: `/events/${event_ticket_number}/confirm`,
        data,
      });
      return response.data;
    } catch (ex: any) {
      throw ex;
    }
  };
}
