import { useForm } from "react-hook-form";
import Html5QrcodePlugin from "../Html5QrcodePlugin";
import { TicketService } from "../services/ticket.service";
import { useState } from "react";
import { Loader } from "../components/loader";
import { useNavigate } from "react-router-dom";
import { Container } from "../components/container";
import { Button } from "../components/button";
import toast from "react-hot-toast";

export const Scanner = () => {
  const { handleSubmit, register } = useForm();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onNewScanResult = async (data: any) => {
    setLoading(true);
    const request = {
      decodedText:
        data.decodedText !== undefined
          ? data.decodedText.split("-").join("")
          : data,
    };
    try {
      const response = await TicketService.getTicketDetails(
        request.decodedText
      );

      switch (response.data.status) {
        case "UTILIZED":
          toast.error("Ticket já utilizado", {
            duration: 10000,
            style: {
              width: "100%",
              border: "0.5px solid #FF4B4B",
              fontSize: "14px",

              fontWeight: 400,
            },
          });
          break;
        case "CANCELED":
          toast.error("Ticket cancelado", {
            duration: 10000,
            style: {
              width: "100%",
              border: "0.5px solid #FF4B4B",
              fontSize: "14px",

              fontWeight: 400,
            },
          });
          break;
        case "EXPIRED":
          toast.error("Ticket expirado", {
            duration: 10000,
            style: {
              width: "100%",
              border: "0.5px solid #FF4B4B",
              fontSize: "14px",

              fontWeight: 400,
            },
          });
          break;
        case "SCHEDULED":
          navigate(`/detalhes`, {
            state: { data: response.data },
          });
          break;
        default:
          navigate(`/detalhes`, {
            state: { data: response.data },
          });
          break;
      }
    } catch (error: any) {
      console.log(error);
      if (error.response.data.error_message === 'Invalid date for this ticket.') {
        toast.error("Ticket inválido para esse dia ou sessão", {
          duration: 10000,
          style: {
            width: "100%",
            border: "0.5px solid #FF4B4B",
            fontSize: "14px",

            fontWeight: 400,
          },
        });
      } else {
        toast.error("Ticket inválido", {
          duration: 10000,
          style: {
            width: "100%",
            border: "0.5px solid #FF4B4B",
            fontSize: "14px",

            fontWeight: 400,
          },
        });
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ marginBottom: 30 }}>
            <p>Leia o QR Code ou digite o ticket</p>
          </div>
          <div>
            <Html5QrcodePlugin
              fps={10}
              qrbox={200}
              aspectRatio={1.0}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            />
          </div>

          <form
            style={{ marginTop: 50 }}
            onSubmit={handleSubmit(onNewScanResult)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <input
                style={{
                  width: "100%",
                  background: "#fff",
                  padding: "0.5rem 1rem",
                  borderRadius: "0.5rem",
                  borderWidth: "1rem",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                placeholder="Ticket"
                {...register("decodedText", { required: true })}
              />

              <Button title={`Confirmar`} type={"submit"} />
            </div>
          </form>
        </>
      )}
    </Container>
  );
};
