import { Container } from "../components/container";
import { Button } from "../components/button";
// import { AuthService } from "../services/auth.service";
import { useForm } from "react-hook-form";
import { useAuth } from "../context/AuthProvider/useAuth";

interface IAuth {
  username: string;
  password: string;
}

export const Auth = () => {
  const { handleSubmit, register } = useForm<IAuth>();

  const { authenticate } = useAuth();

  const login = async (data: IAuth) => {
    try {
      // const response = await AuthService.login({
      //   username: data.username,
      //   password: data.password,
      // });
      await authenticate({
        name: data.username,
        token: { access_token: data.password, refresh_token: "" },
        shopping_uuid: "",
        uuid: "",
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Container>
      <form
        onSubmit={handleSubmit(login)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          alt="Logo Eldorado-prime"
          src="./assets/logo.png"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "180px",
            marginBottom: "40px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <input
            style={{
              width: "100%",
              background: "#fff",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              borderWidth: "1rem",
              marginBottom: 20,
              fontSize: 14,
            }}
            type="email"
            placeholder="Usuário"
            {...register("username", { required: true })}
          />
          <input
            style={{
              width: "100%",
              background: "#fff",
              padding: "0.5rem 1rem",
              borderRadius: "0.5rem",
              borderWidth: "1rem",
              marginBottom: 20,
              fontSize: 14,
            }}
            type="password"
            placeholder="Senha"
            {...register("password", { required: true })}
          />
        </div>
        <Button title={"Entrar"} type={"submit"} />
      </form>
    </Container>
  );
};
