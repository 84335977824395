import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "../components/container";
import { TicketService } from "../services/ticket.service";
import toast from "react-hot-toast";
import { Button } from "../components/button";
import { formatEventTicket } from "../helpers/ticket-formater";
import { format_date_without_time, format_time } from "../helpers/date";
import { useFormatDocument } from "../helpers/document";

export const TicketDetail = () => {
  const location = useLocation().state;

  const navigate = useNavigate();

  const { formatCpf } = useFormatDocument();

  const confirmTicket = async () => {
    try {
      const response = await TicketService.confirm(location.data.ticket, {
        event_ticket_uuid: location.data.uuid,
      });
      toast.success("Ticket liberado", {
        style: {
          width: "100%",
          border: "1px solid #62D244",
          fontSize: "16px",
          letterSpacing: 1,
          fontWeight: 500,
        },
      });
      navigate("/scanner");
    } catch (error) {
      console.log(error);
      toast.error("Recusado");
    }
  };

  return (
    <Container>
      <div style={{ width: 280, height: 380 }}>
        <div
          style={{
            background: "#FFCC07",
            padding: "20px 10px",
            borderRadius: "10px 10px 0 0",
            height: "30%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p
            style={{
              textTransform: "uppercase",
              fontWeight: 700,
              fontSize: 12,
            }}
          >
            Ticket para evento
          </p>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src="https://institucional.shoppingeldorado.com.br/data/files/DC/F2/F4/C0/3E5DC810F2F16BC87A1BF9C2/SE_Marca_Preto_Horizontal.png"
              alt=""
              style={{ width: "120px" }}
            />
            <p style={{ fontWeight: 700, fontSize: 14 }}>
              {location.data.event.name}
            </p>
          </div>
        </div>
        <div
          style={{
            background: "#fff",
            padding: 20,
            borderRadius: "0 0 10px 10px",
            height: "70%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <div>
                  <p style={{ fontSize: 12 }}>Data</p>
                  <p style={{ fontSize: 12, fontWeight: 700 }}>
                    {format_date_without_time(location.data.event_session.date)}
                  </p>
                </div>
                <div>
                  <p style={{ fontSize: 12 }}>Sessão</p>
                  <p
                    style={{ fontSize: 12, fontWeight: 700 }}
                  >{`${format_time(location.data.event_session.time_in)} - ${format_time(location.data.event_session.time_out)}`}</p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginBottom: 20,
              }}
            >
              <div style={{ marginBottom: 10 }}>
                <p style={{ fontSize: 12 }}>Nome</p>
                <p style={{ fontSize: 12, fontWeight: 700 }}>
                  {location.data.customer.name}
                </p>
              </div>
              <div>
                <p style={{ fontSize: 12 }}>Documento</p>
                <p style={{ fontSize: 12, fontWeight: 700 }}>
                  {formatCpf(location.data.customer.document)}
                </p>
              </div>
            </div>
          </div>
          <p style={{ textAlign: "center", fontSize: 20 }}>
            {formatEventTicket(location.data.ticket)}
          </p>
          <Button title={`Confirmar`} onClick={confirmTicket} />
        </div>
      </div>
    </Container>
  );
};
