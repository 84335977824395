export const format_date = (dateString: string): string => {
  const dateToFormat = new Date(dateString);

  let day =
    dateToFormat.getDate() < 10
      ? "0" + dateToFormat.getDate()
      : dateToFormat.getDate().toString();
  let month =
    dateToFormat.getMonth() + 1 < 10
      ? "0" + (dateToFormat.getMonth() + 1)
      : (dateToFormat.getMonth() + 1).toString();
  let year = dateToFormat.getFullYear();

  let hours =
    dateToFormat.getHours() < 10
      ? "0" + dateToFormat.getHours()
      : dateToFormat.getHours().toString();
  let minutes =
    dateToFormat.getMinutes() < 10
      ? "0" + dateToFormat.getMinutes()
      : dateToFormat.getMinutes().toString();

  return day + "/" + month + "/" + year + " " + hours + ":" + minutes;
};

export const format_date_without_time = (dateString: string): string => {
  const dateToFormat = new Date(dateString);

  let day =
    dateToFormat.getDate() < 10
      ? "0" + dateToFormat.getDate()
      : dateToFormat.getDate().toString();
  let month =
    dateToFormat.getMonth() + 1 < 10
      ? "0" + (dateToFormat.getMonth() + 1)
      : (dateToFormat.getMonth() + 1).toString();
  let year = dateToFormat.getFullYear();

  return day + "/" + month + "/" + year;
};

export function format_time(datetime: string) {
  const utcDate = new Date(datetime);

  var localDate = utcDate.toLocaleString("pt-BR", {
    timeStyle: "short",
  });

  return localDate.toString();
}
