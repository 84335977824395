export const useFormatDocument = () => {
  const cpf = /^(\d{3}).?(\d{3}).?(\d{3})-?(\d{2})$/;
  const cnpj = /^(\d{2}).?(\d{3}).?(\d{3})\/?(\d{4})-?(\d{2})$/;
  const inscricaoEstadual = /^(\d{3}).?(\d{3}).?(\d{3}).?(\d{3})$/;
  const telefone =
    /(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:9\d|[2-9])\d{3})-?(\d{4}))/;
  const rg = /^(\d{2}).?(\d{3}).?(\d{3})-?(\d{1})$/;

  const formatCpf = (value: any) => {
    if (value !== undefined) {
      return value.replace(cpf, "$1.$2.$3-$4");
    } else {
      return "";
    }
  };

  const formatCnpj = (value: any) => {
    if (value !== undefined) {
      return value.replace(cnpj, "$1.$2.$3/$4-$5");
    } else {
      return "";
    }
  };

  const formatIncricaoEstadual = (value: any) => {
    if (value !== undefined) {
      return value.replace(inscricaoEstadual, "$1.$2.$3.$4");
    } else {
      return "";
    }
  };

  const formatTelefone = (value: any) => {
    if (value !== undefined) {
      return value.replace(telefone, "$1 ($2) $3-$4");
    } else {
      return "";
    }
  };

  const formatRg = (value: any) => {
    if (value !== null && value !== undefined) {
      return value.replace(rg, "$1.$2.$3-$4");
    } else {
      return "";
    }
  };

  return {
    formatCpf,
    formatCnpj,
    formatIncricaoEstadual,
    formatTelefone,
    formatRg,
  };
};
