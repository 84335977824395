import { createContext } from "react";

import { useCookie } from "../useCookie";

import { useNavigate } from "react-router-dom";
import { IAuthContext, IAuthProvider } from "./IAuthContext";
import { IAuthResponse } from "./IAuthResponse";
import toast from "react-hot-toast";

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [loggedUser, setLoggedUser, clearCookie] = useCookie("user", null);
  const [token, setToken] = useCookie("token", null);
  const [shopping, setShopping] = useCookie("shopping", null);

  const navigate = useNavigate();

  async function authenticate(authentication: IAuthResponse) {
    if (
      authentication.name === "evento@shoppingeldorado.com.br" &&
      authentication.token.access_token === "eld@2024"
    ) {
      setLoggedUser({
        uuid: authentication.uuid,
        name: authentication.name,
      });
      setToken(authentication.token);
      setShopping(authentication.shopping_uuid);

      navigate("/scanner");
    } else {
      toast.error("Usuário ou senha inválidos", {
        style: {
          width: "100%",
          border: "1px solid #FF4B4B",
          fontSize: "16px",
          letterSpacing: 1,
          fontWeight: 500,
        },
      });
      signout();
    }
  }

  async function signout() {
    setLoggedUser(null);
    setToken(null);
    setShopping(null);
    clearCookie("user");
    clearCookie("token");
    clearCookie("shopping");
    navigate("/");
  }

  return (
    <AuthContext.Provider
      value={{ loggedUser, token, shopping, authenticate, signout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
