import { Toaster } from "react-hot-toast";
import "./App.css";
import { AppRoute } from "./routes";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <>
          <AppRoute />
          <Toaster />
        </>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
