import axios from "axios";
import { parseCookies } from "nookies";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_WALLET_API,
});

export const authInstance = axios.create({
  baseURL: process.env.REACT_APP_WALLET_API,
});

authInstance.interceptors.request.use(
  async (config) => {
    const cookies = parseCookies();

    const token = JSON.parse(cookies.token);
    const shopping = JSON.parse(cookies.shopping);

    if (token.access_token) {
      config.headers.Authorization = "Bearer " + token.access_token;
      config.headers.shopping = shopping;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const setupResponseInterceptor = (signOut: any) => {
  authInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response !== undefined && error.response.status === 401) {
        signOut();
      }
    }
  );
};

export const authInstanceFormData = axios.create({
  baseURL: process.env.REACT_APP_WALLET_API,
});

authInstanceFormData.interceptors.request.use(
  async (config) => {
    const cookies = parseCookies();

    const token = JSON.parse(cookies.token);
    const shopping = JSON.parse(cookies.shopping);

    if (token.access_token) {
      config.headers.Authorization = "Bearer " + token.access_token;
      config.headers.shopping = shopping;
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
