import { Route, Routes, useNavigate } from "react-router-dom";
import { Auth } from "../screens/auth";
import { Scanner } from "../screens/scanner";
import { TicketDetail } from "../screens/ticket-detail";
import { useAuth } from "../context/AuthProvider/useAuth";
import { useEffect } from "react";

export const AppRoute = () => {
  const { loggedUser } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (loggedUser === null) {
      navigate("/");
    }
  }, [loggedUser]);

  return (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route path="/scanner" element={<Scanner />} />
      <Route path="/detalhes" element={<TicketDetail />} />
    </Routes>
  );
};
