import React from "react";

export const Container = ({ children }: any) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        height: "100vh",
        background: "#EAE5D9",
      }}
    >
      {children}
    </div>
  );
};
